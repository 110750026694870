import React, { useEffect } from 'react'
import { Tree, Icon } from 'antd'
// import {Helmet} from "react-helmet";
import { useSelector, useDispatch } from 'react-redux'
import { fetchMaskProducts } from '../config/actions'

import "../App.css";

const { TreeNode } = Tree;

const PRODUCT_NAMES = {
    masks: 'Surgical Masks',
    sanitizers: 'Hand Sanitizers',
    disinfectants: 'Disinfectants'
}

const STATE_COLORS = ['#60DA87', '#ccc', '#FA8275']
const GetMaskTab = () => {
    const dispatch = useDispatch()
    const products = useSelector(state => state.data.masksProducts) || {}

	useEffect(() => {
        fetchMaskProducts()(dispatch)
	}, [dispatch])

    function renderHeader(key, items) {
        const available = items.filter(i => i.available).length
        const count = items.length
        return (<div class='flex-row bold'>
            <div>{PRODUCT_NAMES[key]} ({available}/{count})</div>
        </div>)
    }

    function renderState(available) {
        return <div class='dot-2 mr-1' style={{minWidth: 10, backgroundColor: STATE_COLORS[available + 1]}} />
    }

    function renderItems(item) {
        return (<div class='flex-row aCenter'>
            {renderState(item.available)}<div class='ellipsis' onClick={() => window.open(item.url, '_blank')}>{item.name}</div>
        </div>)
    }

    return (
        <div style={{paddingBottom: 30}}>
            <div class='ph'>
                {/* <Helmet>
                    <title>Mask availability aggregator | GoodHood</title>
                    <meta name="description" content="Check availability of nCoV supplies across many stores, updated live." />
                </Helmet> */}
                <div class='bold'>Aggregrated supply info from trusted online retailers</div>
                <div class='bold text-center mt-1'><span style={{color: '#2B70AC'}}>STAY CALM,</span> <span style={{color: '#FF3541'}}>DO NOT HOARD</span></div>
                <div class='flex-row jSb mb-3 mt-3'>
                    <img class='site-logo' alt='' src='https://media.glassdoor.com/sqll/138849/ntuc-fairprice-squarelogo.png'/>
                    <img class='site-logo' alt='' src='https://www.socialbakers.com/www/storage/www/reports/2018-08-01/twitter/962878932876849152.jpg'/>
                    <img class='site-logo' alt='' src='https://static.wixstatic.com/media/52be78_1e0059c812124764955c4688214887e9~mv2.png/v1/fill/w_150,h_151,al_c,usm_0.66_1.00_0.01/Redmart.png'/>
                    <img class='site-logo' alt='' src='https://www.superfoodlab.com/wp-content/uploads/2019/02/guardian-logo.jpg'/>
                    <img class='site-logo' alt='' src='https://upload.wikimedia.org/wikipedia/commons/c/c8/Logo_of_Giant_Hypermarket.svg'/>
                    <img class='site-logo' alt='' src='https://yt3.ggpht.com/a/AGF-l780feMh5aFGN95Du9WpWKioBUv_LG56TMOCBA=s900-c-k-c0xffffffff-no-rj-mo'/>
                </div>
                <div>More added constantly, <a class='bold italic' target='_blank' rel='noopener noreferrer' href='https://wa.me/6588932280?text=Hi,%20please%20tell%20me%20more%20about%20listing%20'>let us know</a> if you have names to add.</div>

                <div class='flex-row jCenter mt-3 mb-2'>
                    <div class='flex-row aCenter mr-3 bold'>{renderState(1)}In Stock</div>
                    <div class='flex-row aCenter ml-2 bold'>{renderState(-1)}Out of Stock</div>
                </div>

                <Tree blockNode switcherIcon={<Icon type="down" />} selectedKeys={['Singapore']}>
                    {
                        ['masks', 'sanitizers', 'disinfectants'].map((key) => {
                            const items = products[key] || [];
                            return <TreeNode blockNode title={renderHeader(key, items)} key={key}>
                                {
                                    items.map((p, pIndex) => (
                                        <TreeNode blockNode isLeaf title={renderItems(p)} key={p.url} />
                                    ))
                                }
                            </TreeNode>
                        })
                    }
                </Tree>
            </div>
            {/* <Divider /> */}
        </div>
    )
}


export default GetMaskTab;