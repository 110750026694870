import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ServiceWorkerProvider } from "../utils/useServiceWorker";
import configStore from "../config/configStore";

import AppContainer from "../App";

const StoreConfig = configStore();

const App = () => {
	return (
		<ServiceWorkerProvider>
			<Provider store={StoreConfig.store}>
				<PersistGate loading={null} persistor={StoreConfig.persistor}>
					<AppContainer />
				</PersistGate>
			</Provider>
		</ServiceWorkerProvider>
	);
};

export default App;
