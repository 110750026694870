import React from 'react'
import { Button } from 'antd'

import "../App.css";

const HeroesTab = () => {
    return (
        <div style={{paddingBottom: 30}}>
            <div class='ph'>
                <div>
                    <a href='https://goodhood.sg' target='_blank'>Share your stories</a> of heroes you encounter to encourage them as they sacrifice to serve others.
                </div>
                <div>
                    Every week, 20 heroes will receive tokens of appreciation ($10 NUTC vouchers). <a href='https://goodhood.sg' target='_blank'>See rules</a>. <a href='https://goodhood.sg' target='_blank'>About us</a>
                </div>
                <div class='flex-row mt-3 mb-3' style={{marginTop: 20}}>
                     <span class='caption'>Supported by</span> <div class='ml-3'>
                         <img class='hero-logo mr-1' alt='' src='/static/sgLogo.png' />
                         <a href='https://ntucsocialenterprises.sg/' target='_blank'><img class='hero-logo' alt='' src='/static/ntucLogo.png' /></a>
                     </div>
                </div>
                <div class='ph mt-3' style={{marginTop: 30}}>
                    <Button block type='primary' onClick={() => window.open('https://wa.me/6588932280?text=Hi,%20please%20tell%20me%20more%20about%20appreciating%20a%20hero%20', '_blank')}>
                        <span class='bold'>Appreciate a Hero!</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default HeroesTab