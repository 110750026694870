import React, { useState, useEffect } from 'react';
// import dayjs from 'dayjs';
import moment from 'moment-timezone';
import {Line} from 'react-chartjs-2';
import { Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux'

import { fetchChart } from '../config/actions'

const CheckboxGroup = Checkbox.Group;

const chinaColor = '#555';
const otherColor = '#EC932F'
const singaporeColor = '#0288d1'

const plainOptions = ['Mainland China', 'Singapore', 'Others'];
const StateChart = ({dimentions}) => {
    const dispatch = useDispatch()
    const chartStateData = useSelector(state => state.data.chart) || []

    useEffect(() => {
        fetchChart()(dispatch)
    }, [dispatch])

    const [checkList, setCheckList] = useState(['Singapore', 'Others'])

    const data = React.useMemo(() => {
        const validData = chartStateData.filter((i) => i.china)
        const okData = validData.reduce((a, c) => {
            a[moment.tz(c.date, 'America/New_York').local().format('MMM DD')] = c;
            return a
        }, {})
        // const labels = validData.map(item => dayjs(item.date).locale('en-SG').format('MMM D'))
        const labels = Object.keys(okData);
        const china = {
            label: 'Mainland China',
            data: labels.map((key) => okData[key].china),
            borderColor: chinaColor,
            pointBorderColor: chinaColor,
            pointBackgroundColor: chinaColor,
            pointHoverBackgroundColor: chinaColor,
            pointHoverBorderColor: chinaColor,
            hoverBackgroundColor: chinaColor,
            hoverBorderColor: chinaColor,
        }
        const others = {
            label: 'Others',
            data: labels.map((key) => okData[key].others),
            borderColor: otherColor,
            pointBorderColor: otherColor,
            pointBackgroundColor: otherColor,
            pointHoverBackgroundColor: otherColor,
            pointHoverBorderColor: otherColor,
            hoverBackgroundColor: otherColor,
            hoverBorderColor: otherColor
        }
        const singapore = {
            label: 'Singapore',
            data: labels.map((key) => okData[key].singapore),
            borderColor: singaporeColor,
            pointBorderColor: singaporeColor,
            pointBackgroundColor: singaporeColor,
            pointHoverBackgroundColor: singaporeColor,
            pointHoverBorderColor: singaporeColor,
            hoverBackgroundColor: singaporeColor,
            hoverBorderColor: singaporeColor
        }
        return {
            labels,
            datasets: [
                china,
                singapore,
                others
            ]
        }
    }, [chartStateData])
    
    const options = React.useMemo(() => {
        return {
            responsive: true,
            aspectRatio: 2,
            maintainAspectRatio: true,
            tooltips: {
                mode: 'label'
            },
            scales: {
                yAxes: [{
                    type: 'linear',
                    display: true,
                    position: 'left',
                    labels: {
                        show: true
                    }
                }],
                xAxes: [{
                    position: 'bottom',
                    gridLines: {
                        display: false,
                    }
                }]
            }
        }
    }, [])
    
    const chartHeight = React.useMemo(() => {
        if (dimentions.width < 300) {
            return 280
        }
        if (dimentions.width < 450) {
            return 240
        }
        if (dimentions.width < 550) {
            return 200
        }
        if (dimentions.width < 700) {
            return 160
        }
        if (dimentions.width < 900) {
            return 150
        }
        return 140
    }, [dimentions])

    const chartData = React.useMemo(() => {
        if (!data.labels) {
            return data
        }
        let res = {
            labels: data.labels,
            datasets: []
        }
        if (checkList.includes('Mainland China')) {
            res.datasets.push(data.datasets[0])
        }
        if (checkList.includes('Singapore')) {
            res.datasets.push(data.datasets[1])
        }
        if (checkList.includes('Others')) {
            res.datasets.push(data.datasets[2])
        }
        return res
    }, [data, checkList])

	return (
		<div style={{
			maxWidth: '100%',
			paddingRight: '2px',
			display: 'flex',
            flexDirection: 'column'
		}}>
			<div class='flex-row' style={{justifyContent: 'space-between', marginBottom: 8}}>
				<div>Total confirmed cases</div>
                <div class='italic caption source-label'>Sources: WHO, JHU</div>
			</div>
            <div style={{position: 'relative',}}>
                <div class='absolute-center water-mark'>
                    <div style={{fontSize: '1.2rem'}}>GOODHOOD.sg</div>
                    <div>strengthening communities</div>
                </div>
                <div>
                    <Line 
                        data={chartData} 
                        height={chartHeight - 10}
                        options={options}
                        legend={{position: 'bottom', display: false}}/>
                </div>
            </div>
            <div class='mt-1 flex center' >
                <CheckboxGroup
                    id='chart-legend'
                    options={plainOptions}
                    value={checkList}
                    onChange={setCheckList}
                />
            </div>
		</div>
	)
}

export default StateChart