import React, { useEffect, useState } from "react";
import { Layout, Tabs, Row, Col, Divider } from "antd";
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';

import useWindowDimensions from './hooks/useWindowDimensions'

import GetMaskTab from './tabs/GetMaskTab'
import HeroesTab from './tabs/HeroesTab'
import StateChart from './components/StateChart'
import SGUpdateComponent from './components/SGUpdateComponent'
import NewsComponent from './components/NewsComponent'
import LocationTable from './components/LocationTable'
import UpdateComponent from './components/UpdateComponent'

import { fetchLocations, logView } from './config/actions'

import "./App.css";

const { Content } = Layout;
const { TabPane } = Tabs;

function formatNumber(value) {
	const number = Number(value)
	return isNaN(number) ? '~' : number.toLocaleString()
}

const HighlightTab = ({dimentions}) => {
	const dispatch = useDispatch()
	React.useEffect(() => {
		fetchLocations()(dispatch)
	}, [dispatch])

	const data = useSelector(state => state.data.highlights ) || {}

	const stats = data.stats || {}
	const lastUpdated = data.locations && data.locations[0] && data.locations[0].provinces[0].lastUpdated
	return (<div style={{paddingLeft: 8, paddingRight: 8, paddingBottom: 30}}>
		<div class='flex-row' style={{justifyContent: 'space-between'}}>
			<div>
				Global cases, as of {moment.tz(lastUpdated, 'America/New_York').local().format('DD MMM YYYY, ha')}
			</div>
			<div>{formatNumber(data.viewCount)} views</div>
		</div>
		<Row justify='center' style={{marginBottom: 20, marginTop: 10}}>
			<Col span={8} style={{textAlign: 'center'}}>
				<span class='confirmed stats-label'>{formatNumber(stats.confirmed)}</span><br/>
				Confirmed
			</Col>
			<Col span={8} style={{textAlign: 'center'}}>
				<span class='deaths stats-label'>{formatNumber(stats.deaths)}</span><br/>
				Deaths
			</Col>
			<Col span={8} style={{textAlign: 'center'}}>
				<span class='recovered stats-label'>{formatNumber(stats.recovered)}</span><br/>
				Recovered
			</Col>
		</Row>
		<StateChart dimentions={dimentions}/>
		<LocationTable data={data}/>

		<Divider />

		<SGUpdateComponent />
		<Divider />
		<NewsComponent />

	</div>)
}

const tabConfigs = {
	stats: {
		text: '#',
		color: '#F4B183'
	},
	masks: {
		img: '/static/maskEmoji.png',
		color: '#F4B183',
	},
	shortcut: {
		text: '+',
		color: '#ED97FF'
	},
	heroes: {
		text: '',
		color: '#28B0EF'
	}
}

const TabTitleView = ({title, style, hasNotification = false, config = {}}) => {
	return (
		<div style={style} class='tab-title-container'>
			<div class='icon-container' style={{backgroundColor: config.color}}>
				{!!config.text && <div class='label-text'>{config.text}</div>}
				{config.img && <img class='icon-container' alt='' src={config.img} />}
			</div>
			<div class='label'>{title}</div>
			{hasNotification && <div class='notification-dot' />}
		</div>
	)
}

function App() {
	const dimentions = useWindowDimensions()

	useEffect(() => {
		logView()
	}, [])

	const [deferredPrompt, setDeferredPrompt] = useState()
	useEffect(() => {
		const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true
		if (!isStandalone) {
			window.addEventListener('beforeinstallprompt', (e) => {
				setDeferredPrompt(e)
			});
		}
	}, [])
	
	const handleAddShortCut = React.useCallback(() => {
		deferredPrompt.prompt();
	}, [deferredPrompt])

	const defaultTab = window.location.hostname.includes('masks') ? 'masks' : window.location.pathname.slice(1) || 'stats'
	const isOldTab = window.location.hostname.startsWith('ncov.') || window.location.hostname === 'localhost'

	const tabBarExtraContent = React.useMemo(() => {
		return !!deferredPrompt && <TabTitleView 
			onClick={handleAddShortCut}
			config={tabConfigs.shortcut} 
			style={{ marginTop: 10, marginRight: 10}} 
			title='Shortcut' />
	}, [deferredPrompt, handleAddShortCut])

	return (
		<Layout style={{alignItems: 'center', minHeight: '100vh'}}>
			<Content style={{backgroundColor: 'white', width: Math.min(dimentions.width, 600), minHeight: '100vh', paddingTop: 5}}>
				<Tabs 
					onChange={(key) => {
						window.history.pushState && window.history.pushState(key, document.title, `/${key}`);
					}}
					defaultActiveKey={defaultTab} 
					tabBarExtraContent={tabBarExtraContent}>
					<TabPane tab={<TabTitleView title='Live stats' config={tabConfigs.stats} />} key="stats">
						<HighlightTab dimentions={dimentions}/>
					</TabPane>
					{isOldTab && <TabPane id='mainTab' tab={<TabTitleView hasNotification={true} title='Heroes' config={tabConfigs.heroes} />} key="heroes">
						<HeroesTab />
					</TabPane>}
					<TabPane tab={<TabTitleView hasNotification={true} title='Masks' config={tabConfigs.masks} />} key="masks">
						<GetMaskTab />
					</TabPane>
				</Tabs>
			</Content>
			<UpdateComponent />
		</Layout>
	);
}

export default App;
