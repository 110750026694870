import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./reducers";

const persistConfig = {
	key: "root",
	storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
	if (module.hot) {
		module.hot.accept("./reducers", () => {
			// This fetch the new state of the above reducers.
			const nextRootReducer = require("./reducers").default;
			store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
		});
	}
	let store = createStore(persistedReducer);
	let persistor = persistStore(store);
	return { store, persistor };
};
