import React from "react";
import { Timeline, Button } from "antd";
import { useSelector, useDispatch } from 'react-redux'
import { fetchSGUpdates } from '../config/actions'

const SGUpdateComponent = () => {
	const dispatch = useDispatch()
	const timelines = useSelector(state => state.data.sgUpdates) || []
    const [showAll, setShowAll] = React.useState(false)

	React.useEffect(() => {
		fetchSGUpdates()(dispatch)
	}, [dispatch]);

	return (
		<div style={{ paddingLeft: 10, paddingRight: 10 }}>
			<div class="flex-row mb-3 bold aCenter"><div class='blinking' style={{width: 10,height:10,borderRadius: 5, backgroundColor: 'red', marginRight: 8}} />Live SG Updates</div>
			<Timeline>
				{timelines.slice(0, showAll ? 1000 : 3).map((item, index) => {
					return (
						<Timeline.Item color="#ccc" key={`${index}`}>
							<div class="flex-row jSb light-text">
								<div class='bold'>
									{item.time}, SG <span role="img"> 🇸🇬</span>
								</div>
								<div class='bold'>gov.sg</div>
							</div>
							<p class='mt-1 bold'>{item.title}</p>
						</Timeline.Item>
					);
				})}
			</Timeline>
            {!showAll && (<div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
				<Button onClick={() => setShowAll(true)} type="link">Show more updates</Button>
			</div>)}
		</div>
	);
};

export default SGUpdateComponent;
