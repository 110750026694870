import axios from "axios";
import { API_URL } from "./constants";
import ActionsTypes from "./actionTypes";

const fetchData = (actionName, apiEndPoint, dispatch) => {
    dispatch({ type: actionName });
	axios
		.post(API_URL + apiEndPoint)
		.then(res => {
			dispatch({
				type: `${actionName}_SUCCESS`,
				payload: res.data
			});
		})
		.catch(err => {
			dispatch({
				type: `${actionName}_FAILURE`,
				payload: err
			});
		});
}

export const fetchLocations = () => dispatch => fetchData(ActionsTypes.FETCH_LOCATIONS, "/locations", dispatch)

export const fetchChart = () => dispatch => fetchData(ActionsTypes.FETCH_CHART, "/chart", dispatch)

export const logView = () => {
    if (process.env.NODE_ENV === 'production') {
        axios.post(API_URL + '/updateView').catch(er => {})
    }
}

export const fetchMaskProducts = () => dispatch => fetchData(ActionsTypes.FETCH_MASKS, "/products", dispatch)

export const fetchSGUpdates = () => dispatch => fetchData(ActionsTypes.FETCH_SG_UPDATES, "/sgTimeline", dispatch)

export const fetchGlobalNews = () => dispatch => fetchData(ActionsTypes.FETCH_GLOBAL_NEWS, "/sgNews", dispatch)

export const fetchCountries = () => dispatch => fetchData(ActionsTypes.FETCH_GLOBAL_NEWS, "/newCountries", dispatch)

export { };
