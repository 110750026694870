import React from "react";
import { notification, Button } from "antd";
import { useServiceWorker } from "../utils/useServiceWorker";

const UpdateComponent = () => {
	const update = useServiceWorker();

	const showUpdatePopup = React.useCallback((update) => {
        const key = "update-button";
        function handleUpdate() {
            update.updateAssets()
            notification.close(key)
        }
		const btn = (
			<Button type="primary" onClick={handleUpdate}>
				Update now
			</Button>
		);
		notification.success({
			message: "Update available",
			description: 'New update avaiable, please press "Update now" to update and reload.',
			placement: "bottomRight",
			btn,
			key,
			duration: 5
		});
	}, []);

	React.useEffect(() => {
        if (update.assetsUpdateReady) {
            showUpdatePopup(update)
        }
    }, [update, showUpdatePopup]);
	return <div />;
};

export default UpdateComponent;
