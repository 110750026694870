import React, { useState } from 'react';
import { Tree, Icon, Button } from 'antd'

import "../App.css";
const { TreeNode } = Tree;

function renderCountryTitle(country) {
    const countryName = country.country === 'Others' ? country.provinces[0].province : country.country
	return (
		<div style={{display: 'flex', flexDirection: 'row'}}>
			<div class='bold location-name'>{countryName || country.country}</div>
			<div style={{display: 'flex', flexDirection: 'row', flex: 3}}>
				<div class='table-header table-number bold'>{Number(country.confirmed).toLocaleString()}</div>
				<div class='table-header table-number bold'>{Number(country.deaths).toLocaleString()}</div>
				<div class='table-header table-number bold'>{Number(country.recovered).toLocaleString()}</div>
			</div>
		</div>
	)
}

function renderProvinceTitle(province) {
	return (
		<div style={{display: 'flex', flexDirection: 'row'}}>
			<div class='location-name' >{province.province}</div>
			<div style={{display: 'flex', flexDirection: 'row', flex: 3}}>
				<div class='table-header table-number'>{Number(province.confirmed).toLocaleString()}</div>
				<div class='table-header table-number'>{Number(province.deaths).toLocaleString()}</div>
				<div class='table-header table-number'>{Number(province.recovered).toLocaleString()}</div>
			</div>
		</div>
	)
}


const LocationTable = ({data}) => {
	const [showAll, setShowAll] = useState(false)
	const locations = React.useMemo(() => {
		const dLocations = data.locations || [];
		dLocations.sort((a, b) => {
			if (a.confirmed === b.confirmed) {
				if (a.deaths === b.deaths) {
					return b.country - a.country
				}
				return b.deaths - a.deaths;
			}
			return b.confirmed - a.confirmed
		})
		const sgIndex = dLocations.findIndex((item) => item.country === 'Singapore')
		if (sgIndex < 0) {
			return []
		}
		const sg = dLocations[sgIndex];
		dLocations.splice(sgIndex, 1);
		dLocations.splice(1, 0, sg)
		return dLocations.slice(0, showAll ? 10000 : 8)
	}, [showAll, data])
	return (
		<div style={{marginTop: 20}}>
			<div style={{display: 'flex', flexDirection: 'row', marginLeft: 16}}>
				<div style={{display: 'flex', flex: 2}} ></div>
				<div style={{display: 'flex', flexDirection: 'row', flex: 3}}>
					<div class='table-header table-number bold table-header-label confirmed'>Confirmed</div>
					<div class='table-header table-number bold table-header-label deaths'>Deaths</div>
					<div class='table-header table-number bold table-header-label recovered'>Recovered</div>
				</div>
			</div>
			<Tree blockNode switcherIcon={<Icon type="down" />} selectedKeys={['Singapore']}>
			{
				locations.map((item) => {
					if (item.provinces.length === 1) {
						return <TreeNode title={renderCountryTitle(item)} key={item.country} />
					}
					return <TreeNode blockNode title={renderCountryTitle(item)} key={item.country}>
						{
							item.provinces.map((p, pIndex) => (
								<TreeNode blockNode isLeaf title={renderProvinceTitle(p)} key={`${item.country}-${p.province}`} />
							))
						}
					</TreeNode>
				})
			}
			</Tree>
			{!showAll && (<div style={{paddingBottom: 0, paddingRight: 10, paddingTop: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
				<Button onClick={() => setShowAll(true)} type="link">Show more countries</Button>
			</div>)}
		</div>
	)
}

export default LocationTable