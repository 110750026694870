import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import ActionsTypes from './actionTypes'

export default handleActions({
    [ActionsTypes.FETCH_LOCATIONS_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                highlights: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_CHART_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                chart: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_MASKS_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                masksProducts: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_SG_UPDATES_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                sgUpdates: {
                    $set: action.payload
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_GLOBAL_NEWS_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            const data = action.payload.map((item) => {
                const tComps = item.timestamp.split(' · ')
                const xIndex = item.title.indexOf('】') + 1
                return {
                    timestamp: tComps[0],
                    source: tComps[1].replace('📌', ''),
                    country: item.title.slice(1, xIndex-1),
                    title: item.title.slice(xIndex),
                    url: item.sourceUrl
                }
            })
            return update(state, {
                globalNews: {
                    $set: data
                }
            })
        }
        return state
    },
    [ActionsTypes.FETCH_COUNTRIES_SUCCESS]: (state = {}, action) => {
        if (action.payload) {
            return update(state, {
                countries: {
                    $set: action.payload
                }
            })
        }
        return state
    }
}, {
    highlights: {},
    countries: [],
    masksProducts: {},
    chart: [],
    sgUpdates: [],
    globalNews: []
})